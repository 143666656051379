import React from "react"
import Discord from "./discord"

export default function SplashUnit() {
  return (
    <div className="splash">
      <h1>Always know when an item comes back in stock.</h1>
      <Discord />
      <div className="splash-disclaimer">
        Refresh is community-supported. When you buy through our links, we may earn an affiliate commission.
      </div>
    </div>
  );
}