import React from "react"
import Layout from "../components/layout"
import DisplayProducts from "../components/displayProducts"
import Testimonials from "../components/testimonials"
import Retailers from "../components/retailers"
import SplashUnit from "../components/splashUnit"
import { hotTrackers } from "../config/hot-trackers"

export default function Splash() {
  const title = {
    title: 'HOT TRACKERS',
    symbol: '🔥',
    symbolLabel: 'fire'
  };
  return (
    <Layout relatedPages={hotTrackers} name="home" showBurger={true} title={title}>
      <SplashUnit />
      <div className="home-modules">
        <DisplayProducts />
        <Testimonials />
        <Retailers />
      </div>
    </Layout>
  );
}